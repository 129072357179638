<template>
    <div>
        
        <v-alert 
            v-model="isAlertVisible"
            type="warning"
            dismissible
        >
            The updated amount for the PO has increased by more than <strong>5%</strong> compared to the original amount. Please get an approval email from the Insight team.
        </v-alert>
        <v-row
            class="app-po-preview"
        >
            
            <v-col
                cols="12"
                md="9"
            >
                <p-o-preview
                    :poData="poData"
                    v-if="preview"
                >
                </p-o-preview>
                <p-o-edit
                    v-else
                    ref="poForm"
                    :originalPOAmount="originalPOAmount"
                    :poData="poData"
                    @warning="newCostOver5Percent">
                </p-o-edit>
            </v-col>
        
            <!-- Right Column: Invoice Action -->
            <v-col
                cols="12"
                md="3"
            >
                <p-o-actions
                    :poData="poData"
                    :preview="preview"
                    @update-po="savePO"
                    @print-po="printPO"
                    @click-preview="clickPreview"
                >
                </p-o-actions>
            </v-col>
            
        </v-row>
    
        <v-snackbar
            v-model="isSnackbarVisibility"
            timeout="2000">
                PO not saved. Please fill out the required fields.
                <template v-slot:action="{ attrs }">
                <v-btn
                    color="error"
                    v-bind="attrs"
                    text
                    @click="isSnackbarVisibility = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
  
<script>
import { ref, onUnmounted, onMounted, getCurrentInstance, watch} from '@vue/composition-api'
import store from '@/store'
import themeConfig from '@themeConfig'
import poStoreModule from '../poStoreModule'
import usePO from '../usePO'
import POActions from '../po-actions/POActions.vue'
import POPreview from '../po-preview/POPreview.vue'
import POEdit from '../po-edit/POEdit.vue'
import html2pdf from 'html2pdf.js';

export default {
    components: {
      POActions,
      POPreview,
      POEdit
    },
    props: {
        projectData: {
            type: Object,
            required: false,
            default: () => {
                return {}
            }
        },
    },
    setup(props) {
        
        const vm = getCurrentInstance().proxy
        const {
            preview,
            poData,
            fetchPO,
            updatePO,
        } = usePO()

        const paymentDetails = ref({})
        const originalPOAmount = ref(0)
        const isAlertVisible = ref(false)
    
        const poForm = ref(null)
        const isInvoiceSendSidebarActive = ref(false)
        const isAddPaymentSidebarActive = ref(false)
        const isSnackbarVisibility = ref(false)
        
    
        // ————————————————————————————————————
        //* ——— Store Registration
        // ————————————————————————————————————
    
        const PO_APP_STORE_MODULE_NAME = 'app-po'
    
        // Register module
        if (!store.hasModule(PO_APP_STORE_MODULE_NAME)) {
            store.registerModule(PO_APP_STORE_MODULE_NAME, poStoreModule)
        }
    
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(PO_APP_STORE_MODULE_NAME)) store.unregisterModule(PO_APP_STORE_MODULE_NAME)
        })
    
        // ————————————————————————————————————
        //* ——— Purchase Order
        // ————————————————————————————————————
    
        const printPO = () => {
            window.print()
        }
    

        const isPOFormValid = () => {

            const isFormValid = vm.$refs.poForm.$refs.refPurchaseOrderForm.validate()
            if (!isFormValid) isSnackbarVisibility .value = true
            else isSnackbarVisibility .value = false
            return isFormValid
        }


        const savePO = (actionPO) => {
            const isValid = isPOFormValid()
            if (isValid){
                const tempPO = JSON.parse(JSON.stringify(poData.value))
                const tempActionPO = JSON.parse(JSON.stringify(actionPO))
                const poLocal = {
                    id: tempPO.id,
                    currency: tempPO.currency.id,
                    contact: tempPO.contact.azure_ad_id,
                    service_date: tempPO.service_date,
                    po_items: tempPO.po_items,
                    project: tempPO.project.job_number,
                    supplier_contact: tempPO.supplier_contact.id,
                    note: tempPO.note,
                    payment_terms: tempActionPO.payment_terms,
                    status: tempActionPO.status,
                    down_payment: tempActionPO.down_payment,
                    home_country: tempActionPO.home_country
                }
                
                updatePO(poLocal)
            }
        }

        
        const downloadPdf = () => {
            html2pdf(vm.$refs.poPdfExport, {
                            margin: 1,
                            filename: `Project ${poData.value.project.job_number} - PO #${poData.value.id}.pdf`,
                            image: { type: 'jpeg', quality: 0.98 },
                            html2canvas: { dpi: 192, letterRendering: true },
                            jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' }
                        }).save()
            // var worker = html2pdf()
            // worker.from(data).save().then((response) => {
            //   console.log(response)
            // })
            // console.log(data, worker )
        }
        
        const clickPreview = () => {
            preview.value = !preview.value
        }

        const newCostOver5Percent = (isVisible) => {
            isAlertVisible.value = isVisible
        }

        onMounted(() => {
            fetchPO()
        })

        watch(
            poData,
            () => {
                
                var total = 0
                for(const item of poData.value.po_items){
                    total += item.unit_price * item.qty
                }
                originalPOAmount.value = total

            }
        )


        return {
            isAlertVisible,
            poForm,
            preview,
            clickPreview,
            poData,
            paymentDetails,
            originalPOAmount,
            newCostOver5Percent,
    
            // poDescription,
            savePO,
            printPO,
            downloadPdf,
    
            // drawer
            isInvoiceSendSidebarActive,
            isAddPaymentSidebarActive,
            isSnackbarVisibility,
    
            // themeConfig
            appName: themeConfig.app.name,
            appLogo: themeConfig.app.logo,
            
        }
    },
  }
  </script>
  
  <style lang="scss">
  @import '~@core/preset/preset/apps/po.scss';
  
  @media print {
    .v-application {
      background: none !important;
    }
  
    .app-navigation-menu,
    .v-app-bar,
    .v-footer,
    .app-po-preview.row .col-12:last-of-type {
      display: none !important;
    }
  
    .app-po-preview.row .col-12:first-child {
      max-width: 100% !important;
      flex: 1 !important;
    }
  
    .v-main,
    .app-content-container {
      padding: 0px !important;
    }
  
    .v-card {
      box-shadow: none !important;
      .print-row {
        flex-direction: row !important;
      }
    }
  
    .app-po-preview {
      .po-header,
      .supplier-details,
      .po-total {
        &.d-flex {
          flex-direction: row !important;
        }
      }
    }
  }
  </style>
  