<template>
    <div>
        <v-card class="mb-6">
            <v-card-title class="pt-1">
                <span>Attachments</span>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        small
                        icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon
                            dense
                            @click="$refs.refInputEl.click()"
                        >
                            {{ icons.mdiPaperclip }}
                        </v-icon>
                    </v-btn>

                </template>
                <span>Upload Attachment</span>
                </v-tooltip>
                

                <input
                    ref="refInputEl"
                    type="file"
                    accept=".jpeg,.png,.jpg,.pdf,.doc,.docx,.csv"
                    :hidden="true"
                    @change="uploadFile"
                />

                <!-- <p class="text-xs mt-2">
                    Allowed JPG, PDF, XLSX or DOCX. Max size of 2MB
                </p> -->
            
            </v-card-title>

            <v-card-text>
                <v-row
                    v-for="(attachment, index) in poFilesList"
                    :key="index"
                >
                    <v-chip
                        :href="attachment.file"
                        target="_blank"
                        class="mb-2"
                        tag="a"
                        outlined
                        download
                        >
                        <v-img
                            :src="extensionToIcon(attachment.filename.split('.').pop()).icon"
                            width="16px"
                            class="me-2"
                        />
                        <span class="text-xs align-text-top">{{ attachment.filename }}</span>
                    </v-chip>
                    <v-spacer></v-spacer>
                    <v-btn
                        small
                        icon
                        @click="deleteFile(attachment.id)"
                        class="mx-0"
                    >
                    <v-icon
                        size="18"
                        >
                        {{ icons.mdiDeleteOutline }}
                        </v-icon>
                    </v-btn>
                </v-row>
                
            
            </v-card-text>
        </v-card>
        
        <v-card class="mb-6">
            <v-card-text>
                
            <v-btn
                color="secondary"
                class="mb-3"
                block
                dark
                @click.native="clickBack"
                >
                    <v-icon
                        left
                        dark
                        class="me-2"
                    >
                        {{ icons.mdiChevronLeft }}
                    </v-icon>
                    <span>Back</span>
                </v-btn>
                
                <v-btn
                    class="mb-3"
                    color="secondary"
                    block
                    outlined
                    @click="$emit('click-preview')"
                    v-if="localPOData.status == 'DRAFT' && $can('update', 'po')"
                >
                    {{!preview ? "Preview" : "Edit PO"}}
                </v-btn>
                <!-- <v-btn
                    class="mb-3"
                    color="secondary"
                    block
                    outlined
                    :to="{ name: 'po-view', params: { id: $route.params.id } }"
                >
                    Edit PO
                </v-btn> -->
                <v-btn
                    class="mb-3"
                    color="warning"
                    block
                    @click="updatePO"
                    v-if="!preview"
                >
                    Update
                </v-btn>
                <v-btn
                    class="mb-3"
                    color="info"
                    block
                    @click="printPO"
                    v-if="preview"
                >
                    Print
                </v-btn>
            </v-card-text>
        </v-card>
            
        <v-select
            v-model="localPOData.payment_terms"
            outlined
            :loading="paymentTermsLoading"
            :items="paymentTermsOptions"
            item-text="label"
            item-value="value"
            dense
            placeholder="Payment Terms"
            label="Payment Terms"
            :menu-props="{ offsetY: true }"
            hide-details="auto"
            class="mb-3"
            :disabled="preview"
        ></v-select>
        <v-select
            v-model="localPOData.status"
            outlined
            :items="statusOptions"
            item-text="label"
            item-value="value"
            dense
            placeholder="PO Status"
            label="PO Status"
            :menu-props="{ offsetY: true }"
            hide-details="auto"
            class="mb-3"
            :disabled="preview"
        ></v-select>
        <v-text-field
            v-model="localPOData.down_payment"
            outlined
            dense
            placeholder="Down Payment"
            label="Down Payment"
            hide-details="auto"
            class="mb-3"
            :disabled="preview"
        ></v-text-field>
        
        <div class="d-flex align-center justify-space-between mb-6">
            <span>Home Country / No Billing needed</span>
            <v-switch
                v-model="localPOData.home_country"
                hide-details="auto"
                class="mt-0"
                :disabled="preview"
            ></v-switch>
        </div>
        <v-card class="mb-6">
            <v-card-title class="pt-2">
                <span>Project POs Cost</span>    
            </v-card-title>
            <v-card-text>
                <v-simple-table dense v-if="localPOData.project">
                    <thead>
                        <tr>
                            <th scope="col">
                            PO #
                            </th>
                            <th scope="col">
                            SUPPLIER
                            </th>
                            <th scope="col">
                            AMOUNT
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(item) in localPOData.project.pos"
                            :key="item.id"
                            @click="clickPO(item)"
                        >
                            <td class="text-wrap text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none">
                                {{ item.id }}
                            </td>
                            <td class="text-wrap">
                            {{ item.supplier }}
                            </td>
                            <td class="text-wrap">
                            {{ item.convertedAmount.toLocaleString('fr-FR', { style: 'currency', currency: localPOData.project.currency}) }}
                            </td>
                        </tr>
                        <tr class="sticky-table-footer">
                            <td colspan="2">Total Cost</td>
                            <td>{{localPOData.project.total_pos_amount.toLocaleString('fr-FR', { style: 'currency', currency: localPOData.project.currency})}}</td>
                        </tr>
                        
                        <tr class="sticky-table-footer" v-if="localPOData.project.oop">
                            <td colspan="2">OOP</td>
                            <td>{{localPOData.project.oop.toLocaleString('fr-FR', { style: 'currency', currency: localPOData.project.currency})}}</td>
                        </tr>
                        
                        <tr class="sticky-table-footer">
                            <td colspan="2">Gross Margin</td>
                            <td>{{localPOData.project.gross_margin.toLocaleString('fr-FR', { style: 'currency', currency: localPOData.project.currency})}} <span :class="`${GMColor(localPOData.project.gm_percent)}`">({{ localPOData.project.gm_percent.toLocaleString('fr-FR', { style: 'percent', maximumFractionDigits: 2}) }})</span></td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
               
        </v-card>
        

        
    </div>
</template>
<script>

    import { mdiSendOutline, mdiPaperclip, mdiDeleteOutline, mdiChevronLeft } from '@mdi/js'
    import { ref, onMounted, watch } from '@vue/composition-api'
    import usePO from '../usePO'
    import router from '@/router'

    export default {
        props: {
            poData: {
                type: Object,
                required: true
            },
            preview: {
                type: Boolean,
                required:false,
                default: () => true
            }
        },
        setup(props, {emit}) {
            
            const localPOData = ref({})

            const resetPOData = () => {
                localPOData.value = JSON.parse(JSON.stringify(props.poData))
                var totalAmount = 0
                if (localPOData.value.project) {
                    for (const po of localPOData.value.project.pos) {
                        if (po.status != 'CANLD') {
                            po.convertedAmount = po.amount * po.exchange_rate
                            totalAmount += po.convertedAmount
                        }
                    }
                    localPOData.value.project.total_pos_amount = totalAmount
                    // localPOData.value.project.gross_margin = localPOData.value.project.budget - totalAmount - localPOData.value.project.oop
                    localPOData.value.project.gm_percent = localPOData.value.project.gross_margin / localPOData.value.project.budget 
                }
            }


            const {
                statusOptions,
                paymentTermsLoading,
                paymentTermsOptions,
                extensionToIcon,
                poFilesList,
                addFile,
                fetchPOFiles,
                deleteFile,
                fetchPaymentTerms
            } = usePO()

            const GMColor = (percent) => {
                if (percent <= 1 && percent > 0.70) return 'success--text'
                if (percent <= 0.70 && percent >= 0.50) return 'warning--text'
                if (percent < 0.50) return 'error--text'
                return 'warning--text'
            }
            

            onMounted( () => {
                fetchPOFiles()
                fetchPaymentTerms()
                resetPOData()
            })


            const uploadFile = (e) => {
                addFile(localPOData.value.id, e.target.files[0] )
                fetchPOFiles()
            }

            const updatePO = () => {
                emit('update-po', localPOData.value)
            }
            const printPO = () => {
                emit('print-po')
            }
            
            const clickBack = () => {
                const currentRoute = router.currentRoute.name
                if (currentRoute == 'po-view') return router.push({ name: 'po' })
                if (currentRoute == 'project-view-po-id') return router.push({ name: 'project-view-po', params: { id: router.currentRoute.params.id } })
                return router.push({ name: 'po' })
            }
            
            const clickPO = (item) => {
                console.log(item)
                const currentRoute = router.currentRoute.name
                if (item.id == router.currentRoute.params.poId) return
                if (currentRoute == 'po-view') return router.push({ name: 'po-view', params: {poId: item.id }})
                if (currentRoute == 'project-view-po-id') return router.push({ name: 'project-view-po-id', params: { id: router.currentRoute.params.id, poId: item.id} })
                return router.push({ path: '/'})
            }

            watch(
                () => props.poData,
                () => {
                    resetPOData()
                }
            )


            return {
                GMColor,
                statusOptions,
                paymentTermsLoading,
                paymentTermsOptions,
                clickBack,
                clickPO,
                extensionToIcon,
                localPOData,
                poFilesList,

                // Icons
                icons: {
                    mdiSendOutline,
                    mdiPaperclip,
                    mdiDeleteOutline,
                    mdiChevronLeft
                },

                updatePO,
                deleteFile,
                uploadFile,
                printPO,
            }
        },
    }
</script>

<style lang="scss">
    @import '~@core/preset/preset/apps/po.scss';
    .sticky-table-footer td {
        font-weight: bold;
        position: sticky;
        bottom: 0;
        background-color: #f8f8f8;
        border-top: thin solid rgba(0,0,0,.12);
        }
</style>
