<template>

    <div 
      ref="poPdfExport"
    >
      <v-card 
          v-if="localPOData.project">
        <!-- Header -->
        <v-card-text 
          class="d-flex flex-wrap justify-space-between flex-column flex-sm-row print-row">
          <!-- Left Content -->
          <div class="mb-4">
            <div class="d-flex align-center mb-6">
              <v-img
                :src="prsinvivo"
                max-height="50px"
                max-width="50px"
                alt="logo"
                contain
                class="me-3"
              ></v-img>
            </div>
              <span class="text--primary font-weight-bold text-uppercase">
                {{ localPOData.project.billed_by.code }}
              </span>
            <!-- Address -->
            <p class="mb-0">{{ localPOData.project.billed_by.address1 }}</p>
            <p class="mb-0">{{ localPOData.project.billed_by.address2 }}</p>
            <p class="mb-0">{{ localPOData.project.billed_by.zip_code }} {{ localPOData.project.billed_by.city }}</p>
            <p class="mb-0">{{ localPOData.project.billed_by.country }}</p>
            <p class="mb-0">{{ localPOData.project.billed_by.phone_number }}</p>
            <p class="mb-0">Tax # {{ localPOData.project.billed_by.tax_id }}</p>
            <p class="mb-0" v-if="localPOData.project.billed_by.org_number">Org # {{ localPOData.project.billed_by.org_number }}</p>
              
          </div>
          <!-- Right Content -->
          <div class="mb-4">
            <h6 class="font-weight-medium text-xl mb-6">
              PO #{{ localPOData.id }}
            </h6>
            <div class="mb-2">
              <span>Date Created: </span>
              <span class="text-md">{{ localPOData.created_at }}</span>
            </div>
            <!-- <div class="mb-2">
              <span>Date Valid. & Sent Final: </span>
              <span class="text-md">{{ localPOData.issue_date }}</span>
            </div> -->
            <div class="mb-2">
              <span>Date of Serv. Rendered: </span>
              <span class="text-md">{{ localPOData.service_date }}</span>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <!-- PO Supplier Details -->
        <v-card-text class="d-flex justify-space-between flex-wrap flex-column flex-sm-row print-row ">
          <div class="my-4">
            <h6 class="text-sm font-weight-medium mb-3">
              PO created for:
            </h6>
            <p class="mb-1">
              {{ localPOData.supplier.name }}
            </p>
            <p class="mb-1">
              To the attention of {{ localPOData.supplier_contact.name }}
            </p>
            <p class="mb-1">
              {{ localPOData.supplier.address1 }}
            </p>
            <p class="mb-1">
              {{ localPOData.supplier.address2 }}
            </p>
            <p class="mb-1">
              {{ localPOData.supplier.zip_code }} {{ localPOData.supplier.city }}
            </p>
            <p class="mb-1" v-if="localPOData.supplier.province">
              {{ localPOData.supplier.province }}
            </p>
            <p class="mb-1">
              {{ localPOData.supplier.country.name }}
            </p>
            <p class="mb-0">
              {{ localPOData.supplier_contact.email }}
            </p>
          </div>
          <div class="my-4">
            <p class="text-sm font-weight-medium mb-0">
              The following reference number must appear on any
            </p>
            <p class="text-sm font-weight-medium mb-3">
              correspondence, delivery document or po :
            </p>
            <table>
              <tr>
                <td class="pe-6">
                  PO NUMBER:
                </td>
                <td>
                  {{ localPOData.id }}
                </td>
              </tr>
              <tr>
                <td class="pe-6">
                  JOB NUMBER:
                </td>
                <td>
                  {{ localPOData.project.ges_bva ? `${localPOData.project.job_number} / ${localPOData.project.ges_bva}` : localPOData.project.job_number}}
                </td>
              </tr>
              <tr>
                <td class="pe-6">
                  JOB NAME:
                </td>
                <td>
                  {{ poData.project.job_name }}
                </td>
              </tr>
            </table>
          </div>
        </v-card-text>
        
        <v-divider></v-divider>

        <!-- Table -->
        <v-simple-table dense>
            <thead>
              <tr>
                <th scope="col">
                  ITEM
                </th>
                <th scope="col">
                  DESCRIPTION
                </th>
                <th scope="col"
                  class="text-center"
                >
                  QUANTITY
                </th>
                <th scope="col"
                  class="text-center"
                >
                  UNIT PRICE
                </th>
                <th scope="col"
                  class="text-center"
                >
                  AMOUNT
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item) in localPOData.po_items"
                :key="item.name"
              >
                <td class="text-wrap">
                  {{ item.order_item_label }}
                </td>
                <td class="text-wrap">
                  {{ item.description }}
                </td>
                <td class="text-center">
                  {{ item.qty }}
                </td>
                <td class="text-center">
                  {{item.unit_price.toLocaleString('fr-FR', { style: 'currency', currency: localPOData.currency.name})}}
                </td>
                <td class="text-center">
                  {{item.total_price.toLocaleString('fr-FR', { style: 'currency', currency: localPOData.currency.name})}}
                </td>
              </tr>
            </tbody>
        </v-simple-table>
        
        <v-divider class="my-2"></v-divider>

        <!-- Total -->
        <v-card-text class="d-flex justify-space-between flex-column flex-sm-row print-row">
          <div class="mb-2">
            <div class="d-flex align-center mb-1">
              <h6 class="text-sm font-weight-medium me-1">PRS IN VIVO Contact:</h6> 
              <span>{{localPOData.contact.name}}</span>
            </div>
            <div class="d-flex align-center mb-0">
              <h6 class="text-sm font-weight-medium me-1">1: </h6> 
              <span>This order must be honored in accordance with the prices,</span>
            </div>
            
            <div class="d-flex align-center mb-0">
              <span>conditions and delivery types above.</span>
            </div>
            <div class="d-flex align-center mb-0">
              <h6 class="text-sm font-weight-medium me-1">2: </h6> 
              <span>If unable to honor this order, please notify us immediately.</span>
            </div>
            <div class="d-flex align-center mb-0">
              <h6 class="text-sm font-weight-medium me-1">3: </h6> 
              <span>Please send your pos by mail in two copies to :</span>
            </div>
                    
            <p class="mb-0"><span class="font-weight-semibold">{{ localPOData.project.billed_by.address1 }}</span></p>
            <p class="mb-0"><span class="font-weight-semibold">{{ localPOData.project.billed_by.address2 }}</span></p>
            <p class="mb-0"><span class="font-weight-semibold">{{ localPOData.project.billed_by.zip_code }} {{ localPOData.project.billed_by.city }}</span></p>
            <p class="mb-0"><span class="font-weight-semibold">{{ localPOData.project.billed_by.country }}</span></p>
            <p class="mb-0"><span class="font-weight-semibold">Phone: {{ localPOData.project.billed_by.phone_number }}</span></p>
            <p class="mb-0"><span class="font-weight-semibold">or by email at {{localPOData.contact.email}}</span></p>
        </div>
          <div>
            <table class="w-full">
              <tr>
                <td class="pe-16">
                  Subtotal:
                </td>
                <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                  <h6 class="text-sm font-weight-medium">
                    {{totalPreTax.toLocaleString('fr-FR', { style: 'currency', currency: localPOData.currency.name})}}
                  </h6>
                </th>
              </tr>
              <tr>
                <td class="pe-16">
                  Tax:
                </td>
                <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                    <h6 class="text-sm font-weight-medium">
                      {{tax.toLocaleString('fr-FR', { style: 'percent', maximumFractionDigits: 2})}}
                    </h6>
                </th>
              </tr>
            </table>
            <v-divider class="mt-4 mb-3"></v-divider>
            <table class="w-full">
              <tr>
                <td class="pe-16">
                  Total:
                </td>
                <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                    <h6 class="text-sm font-weight-medium">
                      {{totalPostTax.toLocaleString('fr-FR', { style: 'currency', currency: localPOData.currency.name})}}
                    </h6>
                </th>
              </tr>
            </table>
          </div>
        </v-card-text>
        
        <v-divider class="mt-2"></v-divider>

        <v-card-text>
          <div class="d-flex">
            <h6 class="text-sm font-weight-medium me-1">
              Note:
            </h6>
            <span>{{ localPOData.note }}</span>
          </div>
        </v-card-text>
      </v-card>
    </div>
    
  </template>
  
  <script>
  import { ref,  computed, watch} from '@vue/composition-api'
  import themeConfig from '@themeConfig'
  import prsinvivo from '@images/logos/prsinvivo.png'
  
  export default {
    props: {
      poData: {
        type: Object,
        required: true
      }
    },
    setup(props) {
      const localPOData = ref({})
      localPOData.value = JSON.parse(JSON.stringify(props.poData))

      const resetPOData = () => {
        localPOData.value = JSON.parse(JSON.stringify(props.poData))
      }

      // ————————————————————————————————————
      //* ——— Purchase Order
      // ————————————————————————————————————
  
     
      const totalPreTax = computed(() => {
        var total = 0
        for(const item of localPOData.value.po_items){
          total += item.unit_price * item.qty
        }
        return total
      })
  
      const tax = 0.0
  
      const totalPostTax = computed(() => {
        return totalPreTax.value * (1+tax)
      })
  

      watch(
        () => props.poData,
        () => {
          resetPOData()
        }
      )
  
      return {
        totalPreTax,
        tax,
        totalPostTax,
        localPOData,
  
        // themeConfig
        appName: themeConfig.app.name,
        appLogo: themeConfig.app.logo,
        prsinvivo
      }
    },
  }
  </script>

  <style lang="scss">
  @import '~@core/preset/preset/apps/po.scss';
  </style>